import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Button,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListItem,
  Tooltip,
} from "@mui/material";
import { Box, Stack, width } from "@mui/system";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DateAndTimeGridCell } from "../../common/DateAndTimeGridCell";
import { useQuery } from "react-query";
import { getCommentDetails } from "../../../services/cluster.js";
import InfoIcon from "@mui/icons-material/Info";
import { useToast } from "../../toast";
import { useTheme } from "@mui/material/styles";
import { DateAndTimeCommentCard } from "../../common/DateAndTimeCommentCard.jsx";
import { ImageViewerSlider } from "./CommentDetails.jsx";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function CommentCard({ data }) {
  const [expanded, setExpanded] = React.useState(false);
  const [showActions, setShowActions] = React.useState();
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <ListItem>
      <Card sx={{ width: "100%", maxWidth: 312 }}>
        <CardHeader
          style={{ paddingBottom: "0px" }}
          avatar={
            <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
              {`${data?.uploadedBy?.[0]}`}
            </Avatar>
          }
          action={
            <IconButton aria-label="settings">
              <MoreVertIcon />
            </IconButton>
          }
          title={
            <Tooltip title={data?.uploadedBy}>
              <Typography
                noWrap
                component={Box}
                variant="caption"
                style={{ width: "132px" }}
                sx={{ textOverflow: "ellipsis" }}
              >
                {data?.uploadedBy}
              </Typography>
            </Tooltip>
          }
          // subheader="24-SEP-2024"
          subheader={
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <DateAndTimeCommentCard
                singleLine={true}
                value={data?.updatedAt}
              />
              <Typography
                sx={{ fontSize: "0.8em", fontWeight: "bold" }}
                variant="button"
              >
                {data?.priority}
              </Typography>
            </div>
          }
        />
        {/* <CardMedia
          component="img"
          height="194"
          image="/static/images/cards/paella.jpg"
          alt="Paella dish"
        /> */}
        <CardContent>
          <Typography
            variant="body2"
            color="text.secondary"
            // style={{ marginBottom: "0.5em" }}
          >
            {/* This impressive paella is a perfect party dish and a fun meal to
            cook together with your guests. Add 1 cup of frozen peas along with
            the mussels, if you like. */}
            {data?.comment}
          </Typography>
        </CardContent>

        <CardActions>
          <div
            style={{
              // marginTop: "1em",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* <Stack direction="row" spacing={2}> */}
            <Typography variant="button" fontSize={10}>
              {data?.attachments?.length
                ? `Attachments (${data?.attachments?.length})`
                : "No Attachments"}
            </Typography>
            {data?.attachments?.length ? (
              <IconButton
                aria-label="delete"
                size="small"
                onClick={handleExpandClick}
              >
                {expanded ? (
                  <KeyboardArrowUpIcon fontSize="inherit" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="inherit" />
                )}
              </IconButton>
            ) : (
              <div></div>
            )}

            {/* </Stack> */}
          </div>

          {showActions ? (
            <Stack style={{ width: "100%" }} direction="row" spacing={2}>
              <Button
                style={{
                  color: "#000000",
                  backgroundColor: "lightgray",
                  width: "128px",
                }}
                variant="contained"
                size="small"
                onClick={handleExpandClick}
              >
                Cancel
              </Button>
              <Button
                style={{
                  color: "#000000",
                  backgroundColor: "lightgray",
                  width: "128px",
                }}
                variant="contained"
                size="small"
              >
                Submit
              </Button>
            </Stack>
          ) : null}
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            {/* <Typography paragraph>Attachments:</Typography> */}
            {/* Attachments list to be shown here */}
            {expanded ? (
              <CommentAttachmentDetails
                details={data}
                simulationUploadId={data?.simulationUploadId}
              />
            ) : null}
          </CardContent>
        </Collapse>
      </Card>
    </ListItem>
  );
}

const CommentAttachmentDetails = ({
  details,
  handleClose,
  simulationUploadId,
}) => {
  const { addToast } = useToast();
  const [commentData, setCommentData] = React.useState(null);

  useQuery(
    [
      "getCommentAttachmentUrls",
      details?.simulationCommentId,
      simulationUploadId,
    ],
    getCommentDetails,
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCommentData(data);
      },
      onError: (err) => {
        const {
          response: { data: errorData },
        } = err;
        if (err && errorData) {
          addToast({
            type: "error",
            message: errorData.message,
            autoClose: 3000,
          });
        }
      },
    }
  );

  return <ThumbnailsList list={commentData?.attachments} />;
};

export function ThumbnailsList({ list }) {
  const theme = useTheme();
  const [showImageViewer, setShowImageViewer] = React.useState(null);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ImageList id="img-list" sx={{ width: 400, height: "auto" }}>
        {list?.map((item, index) => (
          <ImageListItem
            style={{ cursor: "pointer" }}
            key={item.name}
            onClick={() => {
              // setAttachmentIndex(index);
              setShowImageViewer({ initialIndex: index });
            }}
          >
            <img
              style={{
                minHeight: "128px",
                border: `2px solid ${theme.palette.primary[500]}`,
              }}
              // srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
              src={item.url}
              alt={item.name}
              loading="lazy"
            />
            <ImageListItemBar
              title={item.name}
              // subtitle={item.author}
              actionIcon={
                <IconButton
                  sx={{ color: "rgba(255, 255, 255, 0.54)" }}
                  aria-label={`info about ${item.name}`}
                >
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      {showImageViewer && list ? (
        <ImageViewerSlider
          list={list}
          initialIndex={showImageViewer?.initialIndex}
          handleClose={() => {
            setShowImageViewer(null);
          }}
        />
      ) : null}
    </div>
  );
}
