import React, { memo, useState } from "react";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateComment from "./CreateComment";
import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  deleteCommentForSimulation,
  getCommentsForSimulation,
} from "../../../services/cluster";
import { useToast } from "../../toast/useToast.jsx";
import { DateAndTimeGridCell } from "../../common/DateAndTimeGridCell";
import PreviewIcon from "@mui/icons-material/Preview";
import CommentDetails from "./CommentDetails";
import Draggable from "react-draggable";
import CommentIcon from "@mui/icons-material/Comment";
import MessageIcon from "@mui/icons-material/Message";
import { styled } from "@mui/material/styles";
import CommentCard from "./CommentCard";
import CloseIcon from "@mui/icons-material/Close";
import {
  getServerConfiguration,
  getStsToken,
} from "../../../services/cluster.js";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper style={{ width: "90%", maxWidth: "90%" }} {...props} />
    </Draggable>
  );
}

const CommentsList = ({
  open,
  setOpen,
  theme,
  toggleCommentsTable,
  showCommentsTable,
  simulationUploadId,
  toggleCommentsDrawer,
  commentEdit,
}) => {
  const dialogType = "comments";
  const { addToast } = useToast();
  const queryClient = useQueryClient();
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  // const [deleteConfirmDialogProps, setShowDeleteConfirmDialogProps] =
  //   useState(null);
  const [showCommentDetails, setShowCommentDetails] = useState(null);
  const [priority, setPriority] = useState(
    commentEdit ? commentEdit?.priority : "low"
  );

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };
  // const [open, setOpen] = React.useState(false);
  const [simulationsRepoBucket, setSimulationsRepoBucket] = useState(null);
  const [stsToken, setStsToken] = useState(null);

  const handleClickOpen = () => {
    if (open) {
      open === dialogType ? setOpen(false) : setOpen(dialogType);
    } else {
      setOpen(dialogType);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeCommentDetails = () => {
    setShowCommentDetails(null);
  };
  useQuery("getServerConfig", getServerConfiguration, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: serverConfig = {} } = data;
      setSimulationsRepoBucket(serverConfig.s3.SIMULATION_UPLOAD_BUCKET);
    },
    onError: (err) => {
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  useQuery("stsToken", getStsToken, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: token = {} } = data;
      setStsToken(token);
    },
    onError: (err) => {
      // setIsChangeLogError(true);
      const {
        response: { data: errorData },
      } = err;
      if (err && errorData) {
        addToast({
          type: "error",
          message: errorData.message,
          autoClose: 3000,
        });
      }
    },
  });

  let queryName = "fetchCommentsForSimulation",
    page = 1,
    pageSize = 10,
    field = "",
    sort = "desc",
    filterValue,
    columns;

  const { data: { data } = { data: [] } } = useQuery(
    [
      queryName,
      page,
      pageSize,
      field,
      sort,
      filterValue,
      columns,
      simulationUploadId,
    ],
    getCommentsForSimulation,
    {
      enabled: !!simulationUploadId,
      refetchOnWindowFocus: false,
      onSuccess: (response) => {
        const { data: { meta = {}, res = [] } = {} } = response;
        setRows(res);
      },
    }
  );

  const deleteCommentFile = async (data) => {
    if (!simulationsRepoBucket) {
      throw new Error("S3 bucket information not available.");
    }
    // const result = await DeleteFileUsingS3Api({
    //   bucket: simulationsRepoBucket,
    //   key: `${simulationUploadId}/${data?.simulationCommentId}`,
    //   creds: stsToken,
    // });
  };

  // const { mutate: deleteComment } = useMutation(deleteCommentForSimulation, {
  //   onSuccess: async ({ data }) => {
  //     deleteCommentFile(data);
  //   },
  //   onError: (err) => {},
  //   onSettled: () => {
  //     setShowDeleteConfirmDialogProps(null);
  //     queryClient.invalidateQueries("fetchCommentsForSimulation");
  //   },
  // });

  const cols = [
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 70,
      cellClassName: "actions",
      getActions: (d) => {
        const { id, row } = d;
        return [
          <GridActionsCellItem
            icon={<PreviewIcon color="primary" />}
            label="Details View"
            onClick={() => {
              setShowCommentDetails(row);
            }}
            color="inherit"
          />,
          // <GridActionsCellItem
          //   icon={<DeleteIcon />}
          //   label="Delete"
          //   onClick={() => {
          //     const show = true;
          //     const onAccept = () => {
          //       deleteComment(row?.simulationCommentId);
          //     };
          //     const onCancel = () => {
          //       setShowDeleteConfirmDialogProps(null);
          //     };

          //     const props = {
          //       onAccept,
          //       onCancel,
          //       show: true,
          //       // comment: row?.comment,
          //       itemType: "comment",
          //       item: row?.comment,
          //     };
          //     setShowDeleteConfirmDialogProps(props);
          //   }}
          //   color="inherit"
          // />,
        ];
      },
    },
    {
      field: "priority",
      headerName: "Priority",
      width: 70,
      // editable: true,
      // type: "singleSelect",
      // valueOptions: ["High", "Medium", "Low"],
    },
    {
      field: "attachments",
      headerName: "Attachments",
      width: 100,
      renderCell: ({ row }) => {
        return row?.attachments.length;
      },
    },
    {
      field: "comment",
      headerName: "Comment",
      width: 1000,
      flex: 1,
      // editable: true,
    },
    // {
    //   field: "joinDate",
    //   headerName: "Join date",
    //   type: "date",
    //   //   width: 180,
    //   editable: true,
    // },
    {
      field: "uploadedBy",
      headerName: "Created By",
      // type: "number",
      //   width: 80,
      // align: "left",
      // headerAlign: "left",
      // editable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      //   width: 180,
      // editable: true,
      type: "dateTime",
      renderCell: (data) => (
        <DateAndTimeGridCell value={data?.row?.createdAt} />
      ),
    },
  ];

  const createProps = {
    simulationUploadId,
    stsToken,
    simulationsRepoBucket,
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <>
      {/* <Fab
        onClick={handleClickOpen}
        variant="extended"
        size="medium"
        color="primary"
      > */}
      <MessageIcon
        style={{
          cursor: "pointer",
          marginBottom: "0.5em",
          color: open === dialogType ? theme.palette.primary[500] : "darkgrey",
        }}
        sx={{ mr: 1 }}
        onClick={handleClickOpen}
      />
      {/* Comments */}
      {/* </Fab> */}
      {open === dialogType ? (
        <Grid
          container
          style={{
            backgroundColor: "#F2F2F2",
            width: "320px",
            background: "#f2f2f2",
            display: "grid",
            position: "absolute",
            right: "78px",
            top: "60px",
            height: "calc(100vh - 80px)",
          }}
        >
          <List style={{ borderBottom: "1px solid #FFFFFF" }}>
            <CreateComment isPopup={false} {...createProps} />
          </List>
          <List
            style={{
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              height: "99%",
            }}
          >
            {rows?.map((i) => (
              <CommentCard data={i} />
            ))}
            {/* <CommentCard />
            <CommentCard />
            <CommentCard />
            <CommentCard /> */}
          </List>
        </Grid>
      ) : null}

      {/* <Dialog
      >
        <CommentIcon sx={{ mr: 1 }} />
        Comments
      </Fab>
      <Dialog
        id="comments-list-dialog"
        open={false}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: "move" }} id="draggable-dialog-title">
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
            id="draggable-handle"
          >
            <div>
              <Typography variant="h6">Comments</Typography>
            </div>
            <Tooltip title={showCommentsTable ? "Collapse" : "Expand"}>
              <IconButton
                color="primary"
                onClick={() => {
                  toggleCommentsTable();
                }}
              >
                {showCommentsTable ? <ExpandMoreIcon /> : <ExpandLessIcon />}
              </IconButton>
            </Tooltip>
            <div className="dummy-element"></div>

            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </DialogTitle>
        <DialogContent>
          <>
            <div
              style={{
                height: showCommentsTable ? "calc(100vh - 229px)" : "250px",
              }}
            >
              <DataGrid
                style={{ height: "100%" }}
                density="compact"
                rows={rows}
                columns={cols}
                editMode="row"
                rowModesModel={rowModesModel}
                // onRowModesModelChange={handleRowModesModelChange}
                // onRowEditStop={handleRowEditStop}
                // processRowUpdate={processRowUpdate}
                isRowSelectable={false}
                rowSelection={false}
                // isRowEditable={(params) => {
                //   console.log("params = ", params);
                //   return params?.row?.isNew && params?.row?.inEditing;
                // }}
                getRowId={(row) => row?.simulationCommentId}
                // slots={{
                //   toolbar: <Typography>Test toolbar</Typography>,
                //   // <EditToolbar setAddComment={setAddComment} />,
                // }}
                // slotProps={{
                //   toolbar: { setRows, setRowModesModel },
                // }}
              />
            </div>
            {showCommentDetails ? (
              <CommentDetails
                details={showCommentDetails}
                handleClose={closeCommentDetails}
                simulationUploadId={simulationUploadId}
                stsToken={stsToken}
                simulationsRepoBucket={simulationsRepoBucket}
              />
            ) : null}
          </>
        </DialogContent>
        <DialogActions>
          <CreateComment {...createProps} />
        </DialogActions>
      </Dialog> */}
    </>
  );
};

export default memo(CommentsList);
