import React, { memo, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { styled } from "@mui/material/styles";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useMutation, useQueryClient } from "react-query";
import {
  addCommentForSimulation,
  addJiraTicketAttachment,
  createJiraTicket,
  updateCommentForSimulation,
} from "../../../services/cluster.js";
import CommentImageSelector from "./CommentImageSelector.jsx";
import uploadFileUsingS3Api from "../../../services/AwsFileUploader.js";
import { GridCloseIcon } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import { Stack } from "@mui/system";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateComment = ({
  isPopup,
  simulationUploadId,
  commentEdit = null,
  stsToken,
  simulationsRepoBucket,
  handleOptionClose = () => {},
}) => {
  const [commentValue, setCommentValue] = useState(
    commentEdit ? commentEdit?.comment : ""
  );
  const [noChange, setNochange] = useState(true);
  const [priority, setPriority] = useState(
    commentEdit ? commentEdit?.priority : "Low"
  );

  const [open, setOpen] = useState(false);
  const [attachments, setAttachments] = useState([]);
  const [attachmentNames, setAttachmentNames] = useState(
    commentEdit
      ? commentEdit?.attachments?.map((i) => {
          return i.name;
        })
      : []
  );

  // const { mutate: addAttachmentToJiraTicket } = useMutation(
  //   addJiraTicketAttachment,
  //   {
  //     onSuccess: async ({ data }) => {
  //       console.log("Added the attachments to Jira Ticket!");
  //     },
  //     onError: (err) => {
  //       console.log("Error while adding attachments");
  //     },
  //   }
  // );

  const { mutate: createTicket } = useMutation(createJiraTicket, {
    onSuccess: async ({ data }) => {
      console.log("TICKET CREATED");
    },
    onError: (err) => {},
    onSettled: () => {},
  });

  const [uploadProgress, setUploadProgress] = useState(0);
  const [currentFileBeingUploaded, setCurrentFileBeingUploaded] = useState("");
  const [uploadedItems, setUploadedItems] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [allFilesUploadComplete, setAllFilesUploadComplete] = useState(false);
  const [needToCreateJiraTicket, setNeedToCreateJiraTicket] = useState(false);

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
    setNochange(false);
  };

  const updateAttachmentsList = (newItems) => {
    setAttachments(newItems);
    const names = newItems?.map((i) => {
      return i?.name;
    });
    setAttachmentNames([...new Set([...attachmentNames, ...names])]);
  };

  const simulationUploadAction = async ({ file, data }) => {
    try {
      // setImageUploadActionLoading(true);
      if (!simulationsRepoBucket) {
        throw new Error("S3 bucket information not available.");
      }
      const result = await uploadFileUsingS3Api({
        bucket: simulationsRepoBucket,
        key: `${simulationUploadId}/${data?.simulationCommentId}/${file?.name}`,
        selectedFile: file,
        onInit: (instance) => {
          // setUploadInstance?.(instance || null);
        },
        onProgress: (progress) => {
          console.log("Progress data = ", progress);
          // setUploadStatus(UploadStatus.InProgress);
          if (progress?.part === 2) {
            // updateUploadStatus(
            //   data.simulationUploadId,
            //   UploadStatus.InProgress
            // );
          }
          // setUploadProgress({
          //   ...progress,
          //   uploadPct: progress.loaded / progress.total,
          // });
          const percentage = progress.loaded / progress.total;
          // console.log("Percentage  = ", percentage);
          // setUploadProgress(percentage);
        },
        creds: stsToken,
      });

      if (result) {
        // setUploadStatus(UploadStatus.Completed);
        // updateUploadStatus(data.simulationUploadId, UploadStatus.Completed);
      }
    } catch (error) {
      // const { message = IMAGE_UPLOAD_ERRORS.SOMETHING_WENT_WRONG } = error;
      console.log(error);
      // setErrorMsg(message);
      // setUploadStatus(UploadStatus.Failed);
    } finally {
      // setImageUploadActionLoading(false);
    }
  };

  const startUploadingFiles = async (data, isUpdate) => {
    setIsUploading(true);
    const itemsUploaded = [];
    for (const item of attachments) {
      // start of uploading
      setUploadProgress(0);
      setCurrentFileBeingUploaded(item?.name);

      // start file upload
      const contents = await simulationUploadAction({ file: item?.file, data });
      // await new Promise((resolve) => setTimeout(resolve, 2000));

      // After upload is done for this file
      // const uploadedFiles = JSON.parse(JSON.stringify(uploadedItems));
      // uploadedFiles[file?.name] = true;
      itemsUploaded.push(item?.name);
      // const newItems = [...uploadedItems, file?.name];
      console.log("New uploaded Items", itemsUploaded);
      setUploadedItems(itemsUploaded);
    }
    setCurrentFileBeingUploaded("");
    setAllFilesUploadComplete(true);
    // resetCreateForm();
    handleClose();
    if (!isUpdate && needToCreateJiraTicket) {
      const payload = {
        simulationUploadId: simulationUploadId,
        simulationCommentId: data?.simulationCommentId,
        comment: commentValue,
        priority: priority,
        attachments: attachmentNames,
      };
      createTicket(payload);
    }
    // resetUploadForm();
    // handleClose();
  };

  const queryClient = useQueryClient();

  const { mutate: updateComment } = useMutation(updateCommentForSimulation, {
    onSuccess: async ({ data }) => {
      const isUpdate = true;
      startUploadingFiles(data, isUpdate);
    },
    onError: (err) => {},
    onSettled: () => {
      queryClient.invalidateQueries("fetchCommentsForSimulation");
      queryClient.invalidateQueries("getCommentAttachmentUrls");
    },
  });

  const { mutate: addComment } = useMutation(addCommentForSimulation, {
    onSuccess: async ({ data }) => {
      const isUpdate = false;
      startUploadingFiles(data, isUpdate);
    },
    onError: (err) => {},
    onSettled: () => {
      queryClient.invalidateQueries("fetchCommentsForSimulation");
    },
  });

  const update = () => {
    setIsUploading(true);
    const payload = {
      simulationUploadId: simulationUploadId,
      simulationCommentId: commentEdit?.simulationCommentId,
      comment: commentValue,
      priority: priority,
      attachments: attachmentNames,
    };
    updateComment(payload);
  };

  const add = () => {
    setIsUploading(true);
    const payload = {
      simulationUploadId: simulationUploadId,
      comment: commentValue,
      priority: priority,
      attachments: attachmentNames,
    };
    addComment(payload);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const resetCreateForm = () => {
    setCommentValue("");
    setPriority("Low");
    setAttachments([]);
    setAttachmentNames([]);
    setUploadProgress(0);
    setCurrentFileBeingUploaded("");
    setUploadedItems([]);
    setIsUploading(false);
    setAllFilesUploadComplete(false);
  };

  const handleClose = () => {
    handleOptionClose();
    setIsUploading(false);
    resetCreateForm();
    setOpen(false);
  };

  const handleSave = () => {
    if (commentEdit) {
      update();
    } else {
      add();
    }
    handleOptionClose();
  };

  const handleChangeJiraCreation = (e) => {
    setNeedToCreateJiraTicket(e?.target?.checked);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <React.Fragment>
      <ListItem style={{ display: "flex" }}>
        <Stack direction="row" spacing={17}>
          <ListItemText
            primary={
              <Typography
                variant="body1"
                style={{ fontWeight: "bold", color: "gray" }}
              >
                Comments
              </Typography>
            }
            // secondary={simulationDetails?.simulationName}
          />
          {!open ? (
            <>
              {commentEdit ? (
                <MenuItem
                  onClick={() => {
                    handleClickOpen();
                  }}
                  disableRipple
                >
                  <EditIcon />
                  Edit
                </MenuItem>
              ) : (
                <>
                  <Button
                    size="small"
                    variant="outlined"
                    startIcon={<Add />}
                    onClick={() => {
                      handleClickOpen();
                    }}
                  >
                    {commentEdit ? "Update" : "Add"}
                  </Button>
                </>
              )}
            </>
          ) : null}
        </Stack>
      </ListItem>

      <>
        {isPopup ? (
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Enter new comment
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <GridCloseIcon />
            </IconButton>
            <DialogContent>
              <Box
                sx={{
                  width: 500,
                  maxWidth: "100%",
                }}
              >
                <TextField
                  style={{ marginTop: "1em" }}
                  fullWidth
                  label="Comment"
                  id="comment"
                  value={commentValue}
                  onChange={(event) => {
                    setCommentValue(event.target.value);
                  }}
                />
              </Box>
              <FormControl style={{ width: "200px", marginTop: "1em" }}>
                <InputLabel id="priority">Priority</InputLabel>
                <Select
                  labelId="priority"
                  id="priority-simple-select"
                  value={priority}
                  label="Priority"
                  onChange={handlePriorityChange}
                >
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Medium">Medium</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </Select>
              </FormControl>

              <Box>
                <CommentImageSelector
                  simulationUploadId={simulationUploadId}
                  updateAttachmentsList={updateAttachmentsList}
                  uploadedItems={uploadedItems}
                  currentFileBeingUploaded={currentFileBeingUploaded}
                  uploadProgress={uploadProgress}
                  isUploading={isUploading}
                  allFilesUploadComplete={allFilesUploadComplete}
                  handleClose={handleClose}
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} disabled={isUploading}>
                Close
              </Button>
              <Button onClick={handleSave} disabled={isUploading}>
                Save
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          <>
            {open && (
              <>
                <ListItem style={{ paddingBottom: "0" }}>
                  <TextField
                    style={{ width: "100%" }}
                    label="Add comment"
                    multiline
                    rows={4}
                    // defaultValue="Default Value"
                    variant="filled"
                    id="comment"
                    value={commentValue}
                    onChange={(event) => {
                      setCommentValue(event.target.value);
                    }}
                  />
                </ListItem>
                <ListItem style={{ paddingTop: "0", paddingBottom: "0" }}>
                  <FormControlLabel
                    style={{ fontSize: "0.8em" }}
                    control={<Checkbox onChange={handleChangeJiraCreation} />}
                    label="Create JIRA ticket for comment"
                  />
                </ListItem>
                <ListItem>
                  <Stack direction="row" spacing={2}>
                    {/* <Button
                      style={{
                        color: "#000000",
                        backgroundColor: "lightgray",
                        width: "132px",
                      }}
                      size="small"
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={
                        <AttachFileIcon
                          style={{ transform: "rotate(45deg)" }}
                        />
                      }
                    >
                      Attach
                      <VisuallyHiddenInput type="file" />
                    </Button> */}

                    <CommentImageSelector
                      layout="small"
                      simulationUploadId={simulationUploadId}
                      updateAttachmentsList={updateAttachmentsList}
                      uploadedItems={uploadedItems}
                      currentFileBeingUploaded={currentFileBeingUploaded}
                      uploadProgress={uploadProgress}
                      isUploading={isUploading}
                      allFilesUploadComplete={allFilesUploadComplete}
                      handleClose={handleClose}
                    />
                    <FormControl style={{ width: "132px", marginLeft: "1em" }}>
                      <InputLabel id="priority">Priority</InputLabel>
                      <Select
                        size="small"
                        labelId="priority"
                        id="priority-simple-select"
                        value={priority}
                        label="Priority"
                        onChange={handlePriorityChange}
                      >
                        <MenuItem value="High">High</MenuItem>
                        <MenuItem value="Medium">Medium</MenuItem>
                        <MenuItem value="Low">Low</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                </ListItem>
                <ListItem sx={{ p: 0 }}>
                  <div
                    id="upload-images-list-preview-portal"
                    style={{ width: "100%" }}
                  ></div>
                </ListItem>
                {/* <ListItem>
                  <div className="attachments-preview-list"></div>
                </ListItem> */}
                <ListItem>
                  <Stack direction="row" spacing={2}>
                    <Button
                      style={{
                        color: "#000000",
                        backgroundColor: "lightgray",
                        width: "132px",
                      }}
                      variant="contained"
                      size="medium"
                      onClick={handleClose}
                      disabled={isUploading}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{
                        color: "#000000",
                        backgroundColor: "lightgray",
                        width: "132px",
                      }}
                      variant="contained"
                      size="medium"
                      onClick={handleSave}
                      disabled={isUploading}
                    >
                      Submit
                    </Button>
                  </Stack>
                </ListItem>
              </>
            )}
          </>
        )}
      </>
      {/* {commentEdit ? (
        <MenuItem
          onClick={() => {
            handleClickOpen();
          }}
          disableRipple
        >
          <EditIcon />
          Edit
        </MenuItem>
      ) : (
        <Button
          size="small"
          variant="outlined"
          startIcon={<Add />}
          onClick={() => {
            handleClickOpen();
          }}
        >
          Add Comment
        </Button>
      )} */}
      {/* <Dialog
        open={open}
        TransitionComponent={Transition}
        // keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Enter new comment
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <GridCloseIcon />
        </IconButton>
        <DialogContent>
          <Box
            sx={{
              width: 500,
              maxWidth: "100%",
            }}
          >
            <TextField
              style={{ marginTop: "1em" }}
              fullWidth
              label="Comment"
              id="comment"
              value={commentValue}
              onChange={(event) => {
                setCommentValue(event.target.value);
                setNochange(false);
              }}
            />
          </Box>
          <FormControl style={{ width: "200px", marginTop: "1em" }}>
            <InputLabel id="priority">Priority</InputLabel>
            <Select
              labelId="priority"
              id="demo-simple-select"
              value={priority}
              label="Priority"
              onChange={handlePriorityChange}
            >
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
              <MenuItem value="Low">Low</MenuItem>
            </Select>
          </FormControl>

          <Box>
            <CommentImageSelector
              simulationUploadId={simulationUploadId}
              updateAttachmentsList={updateAttachmentsList}
              uploadedItems={uploadedItems}
              currentFileBeingUploaded={currentFileBeingUploaded}
              uploadProgress={uploadProgress}
              isUploading={isUploading}
              allFilesUploadComplete={allFilesUploadComplete}
              handleClose={handleClose}
              setNochange={setNochange}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} disabled={isUploading}>
            Close
          </Button>
          <Button onClick={handleSave} disabled={isUploading || noChange}>
            Save
          </Button>
        </DialogActions>
      </Dialog> */}
    </React.Fragment>
  );
};

export default memo(CreateComment);
